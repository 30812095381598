import { lettersAndSpace, onlyNumber, validEmail } from '@utils';
import { useEffect, useState } from 'react';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import CloseIcon from '@mui/icons-material/Close';
import { CustomTextInput } from '@components';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import IconButton from '@mui/material/IconButton';
import InputLabel from '@mui/material/InputLabel';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import classNames from 'classnames';
import dayjs from 'dayjs';
import every from 'lodash/every';
import { useRequestBookingMutation } from '@services';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { useMediaQueries } from '@hooks';

export default function BookingGuidedTourDialog({
  slug,
  cta,
  open,
  tickets,
  setShowBookingTourDialog,
}) {
  const { locale } = useRouter();

  // State component
  const [allowSubmit, setAllowSubmit] = useState(false);

  const [formValues, setFormValues] = useState({
    product_slug: {
      value: tickets[0]?.slug ?? null,
      valid: true,
    },
    name: { value: null, valid: false },
    email: { value: null, valid: false },
    phone: { value: null, valid: false },
    num_pax: { value: 0, valid: false },
    date_service: { value: null, valid: false },
    hour_service: { value: null, valid: false },
    language_code: { value: locale, valid: true },
    message: { value: null, valid: false },
    newsletter: { value: 1, valid: true },
    profilation: { value: 0, valid: true },
    anonymization: { value: 0, valid: true },
    privacy: { value: 0, valid: false },
  });

  // Services
  const [requestBooking] = useRequestBookingMutation();

  // i18n
  const { t } = useTranslation('common');

  // Material Ui hooks
  const { isMobile } = useMediaQueries();
  // Events
  const onClose = () => {
    setShowBookingTourDialog(false);
    setFormValues({
      product_slug: { value: slug, valid: true },
      name: { value: null, valid: false },
      email: { value: null, valid: false },
      phone: { value: null, valid: false },
      num_pax: { value: 0, valid: false },
      date_service: { value: null, valid: false },
      hour_service: { value: null, valid: false },
      language: { value: locale, valid: true },
      message: { value: '', valid: true },
      newsletter: { value: 0, valid: true },
      profilation: { value: 0, valid: true },
      anonymization: { value: 0, valid: true },
      privacy: { value: 0, valid: true },
    });
  };

  useEffect(() => {
    setAllowSubmit(every(formValues, 'valid'));
  }, [formValues]);

  const onSubmit = async () => {
    try {
      // Madonna santa quanto fanno schifo i form non ho parole
      let body = {};
      Object.keys(formValues)
        .map((k) => ({ [k]: formValues[k].value }))
        .map((o) => (body = { ...body, ...o }));

      const { data } = await requestBooking({
        slug,
        data: {
          ...body,
          date_service: dayjs(body.date_service).format(
            'YYYY-MM-DD',
          ),
        },
      });

      if (data?.success && data?.status == 'success') {
        onClose();
      }
    } catch (error) {
      console.error('onSubmitRequestBookingForm', error);
    }
  };

  return (
    <Dialog
      onClose={onClose}
      fullWidth
      maxWidth="sm"
      fullScreen={isMobile}
      open={open}
    >
      <DialogTitle className="text-base text-center text-gray-700 dark:text-white">
        {cta}
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            top: 8,
            right: 8,
            position: 'absolute',
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Box
          component="form"
          autoComplete="off"
          className={classNames('request-booking-form', 'w-full')}
        >
          {/*    <code>
            <pre>{JSON.stringify({ formValues }, null, 2)}</pre>
          </code> */}

          <div className="space-y-4">
            <CustomTextInput
              block
              required
              fullWidth
              type="text"
              size="small"
              label={t('forms.fullname')}
              margin="normal"
              reset={true}
              minLength={4}
              variant="outlined"
              regex={lettersAndSpace}
              //helperText="Il valore inserito non è corretto"
              onSetValue={(value) =>
                setFormValues({
                  ...formValues,
                  name: value,
                })
              }
            />

            <CustomTextInput
              block
              required
              fullWidth
              type="email"
              size="small"
              label="Email"
              margin="normal"
              reset={true}
              minLength={5}
              variant="outlined"
              regex={validEmail}
              onSetValue={(value) =>
                setFormValues({
                  ...formValues,
                  email: value,
                })
              }
            />

            <CustomTextInput
              block
              required
              fullWidth
              type="text"
              size="small"
              label={t('forms.phone')}
              margin="normal"
              reset={true}
              minLength={5}
              variant="outlined"
              regex={onlyNumber}
              onSetValue={(value) =>
                setFormValues({
                  ...formValues,
                  phone: value,
                })
              }
            />

            <CustomTextInput
              block
              required
              fullWidth
              type="number"
              size="small"
              label={t('forms.pax')}
              margin="normal"
              reset={true}
              minLength={1}
              variant="outlined"
              regex={onlyNumber}
              onSetValue={(value) =>
                setFormValues({
                  ...formValues,
                  num_pax: value,
                })
              }
            />
          </div>

          <div className="mt-4 mb-2 space-y-4">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label={t('forms.day')}
                inputFormat="DD/MM/YYYY"
                disablePast
                // views={['year', 'month', 'day']}
                value={formValues.date_service.value}
                onChange={(day) => {
                  if (dayjs(day).isValid()) {
                    setFormValues({
                      ...formValues,
                      date_service: {
                        value: day,
                        valid: true,
                      },
                    });
                  }
                }}
                renderInput={(params) => (
                  <TextField fullWidth required {...params} />
                )}
              />
            </LocalizationProvider>

            <FormControl fullWidth required>
              <InputLabel id="slot-label">Slot</InputLabel>
              <Select
                labelId="slot-label"
                value={formValues.hour_service.value}
                label="Slot"
                required
                onChange={({ target }) =>
                  setFormValues({
                    ...formValues,
                    hour_service: {
                      value: target.value,
                      valid: true,
                    },
                  })
                }
              >
                {[
                  '08:30',
                  '09:00',
                  '09:30',
                  '10:00',
                  '10:30',
                  '11:00',
                  '11:30',
                  '12:00',
                  '12:30',
                  '13:00',
                  '13:30',
                  '14:00',
                  '14:30',
                  '15:00',
                  '15:30',
                  '16:00',
                  '16:30',
                  '17:00',
                  '17:30',
                  '18:00',
                ].map((s) => (
                  <MenuItem key={s} value={s}>
                    {s}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl fullWidth required>
              <InputLabel id="type-label">
                {t('forms.type')}
              </InputLabel>
              <Select
                labelId="type-label"
                value={formValues.product_slug.value}
                label={t('forms.type')}
                required
                onChange={({ target }) =>
                  setFormValues({
                    ...formValues,
                    product_slug: {
                      value: target.value,
                      valid: true,
                    },
                  })
                }
              >
                {tickets.map((t, i) => (
                  <MenuItem key={i} value={t?.slug}>
                    {t.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>

          <CustomTextInput
            block
            rows={5}
            fullWidth
            required
            type="text"
            size="small"
            regex={null}
            minLength={0}
            margin="normal"
            multiline={true}
            label={t('forms.message')}
            maxLength={3000}
            variant="outlined"
            onSetValue={(value) =>
              setFormValues({
                ...formValues,
                message: value,
              })
            }
          />

          <FormControl className="px-2">
            <FormControlLabel
              label={
                <span className="text-xs md:text-sm">
                  {t('forms.accept_privacy')}
                  <a
                    className="mx-1 font-bold underline underline-offset-4"
                    href="/documents/M-GDPR-30-informativa-sito-internet.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Privacy Policy
                  </a>
                </span>
              }
              control={
                <Checkbox
                  checked={Boolean(formValues.privacy.value)}
                  onChange={(e) => {
                    const val = e.target.checked;
                    setFormValues({
                      ...formValues,
                      privacy: {
                        value: +val,
                        valid: val,
                      },
                    });
                  }}
                />
              }
            />
          </FormControl>
        </Box>
      </DialogContent>
      <DialogActions className="pt-4 pb-6">
        <Button
          autoFocus
          onClick={onSubmit}
          disabled={!allowSubmit}
          className={classNames(
            'mx-auto w-full bg-primary text-white md:w-6/12',
            { 'opacity-50': !allowSubmit },
          )}
        >
          {t('buttons.send')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
