import {
  createApi,
  fetchBaseQuery,
} from '@reduxjs/toolkit/query/react';

import omit from 'lodash/omit';

const baseUrl = process.env.NEXT_PUBLIC_ADITUS;

export const CartService = createApi({
  reducerPath: 'Cart',
  tagTypes: ['Cart'],
  baseQuery: fetchBaseQuery({
    baseUrl,
    prepareHeaders: (headers, { getState }) => {
      const token = getState().user.access_token;
      if (token) {
        headers.set('Accept', 'application/json');
        headers.set('Authorization', `Bearer ${token}`);
        return headers;
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    // GET CART
    getCart: builder.query({
      providesTags: ['Cart'],
      keepUnusedDataFor: 0,
      query: ({ locale = 'it' }) => {
        return `/v3/cart_products?lang=${locale}`;
      },
      transformResponse: (res, meta, arg) => {
        return {
          products: res?.data || [],
          created_at: res.data || null,
          ...omit(res, 'data'),
        };
      },
    }),
    getCartCreationDate: builder.query({
      // keepUnusedDataFor: 0,
      query: () => `/carts`,
      providesTags: ['Cart'],
    }),
    setCart: builder.mutation({
      query: (cart) => {
        const formData = new FormData();
        formData.append('products', JSON.stringify(cart.products));
        return {
          url: `/v3/cart_products`,
          method: 'POST',
          body: formData,
        };
      },
      invalidatesTags: ['Cart'],
      transformResponse: (res, meta) => res,
    }),

    // product-holders
    setCardHolders: builder.mutation({
      query: (data) => {
        const formData = new FormData();
        formData.append('holders', JSON.stringify(data));
        return {
          url: `/v3/carts/product-holders`,
          method: 'POST',
          body: formData,
        };
      },
      invalidatesTags: ['Cart'],
      transformResponse: (res, meta) => res,
    }),

    deleteCartItem: builder.mutation({
      query: ({ id }) => {
        return {
          url: `/v3/cart_products/${id}`,
          method: 'DELETE',
        };
      },
      invalidatesTags: ['Cart'],
      transformResponse: (res, meta, arg) => res,
    }),
    deleteCartItems: builder.mutation({
      query: ({ ids }) => {
        return {
          url: `/v3/cart_products?ids=[${ids.toString()}]`,
          method: 'DELETE',
        };
      },
      invalidatesTags: ['Cart'],
      transformResponse: (res, meta, arg) => res,
    }),
  }),
});

export const {
  useGetCartQuery,
  useSetCartMutation,
  useSetCardHoldersMutation,
  useDeleteCartItemMutation,
  useGetCartCreationDateQuery,
  useDeleteCartItemsMutation,
} = CartService;

export default CartService;
