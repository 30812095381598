import {
  createApi,
  fetchBaseQuery,
} from '@reduxjs/toolkit/query/react';

// const baseUrl = 'https://api.openweathermap.org/data/2.5/forecast/daily?';
const baseUrl = 'https://api.weatherapi.com/v1/forecast.json';

export const WeatherService = createApi({
  reducerPath: 'Weather',
  tagTypes: ['Weather'],
  baseQuery: fetchBaseQuery({
    baseUrl,
    prepareHeaders: (headers, { getState }) => {
      return headers;
    },
  }),
  onQueryStarted: () => {
    console.info('onQueryStarted Weather');
  },
  endpoints: (builder) => ({
    getWeather: builder.query({
      query: ({ lat, lng }) =>
        `?key=cde96bec6cc44ea9808134754230303&q=${lat},${lng}&days=7&aqi=no&alerts=no`,
      transformResponse: (res) => {
        return res;
      },
    }),
  }),
});

export const { useGetWeatherQuery } = WeatherService;

export default WeatherService;
