import {
  Actions,
  Calendar,
  Recap,
  ServiceSlots,
  SiteSlots,
  Tickets,
} from './index';
import { setCartReducer, userSelector } from '@slices';
import {
  shapeCartClientSide,
  shapeCartServerSide,
  toggleBlurLayout,
} from '@utils';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import {
  useGetServiceTicketsQuery,
  useGetSiteTourBySlugQuery,
  useLazyGetServiceTicketsByDateAndHourQuery,
  useLazyGetTicketsByDateAndHourQuery,
  useSetCartMutation,
} from '@services';

import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import CircularProgress from '@mui/material/CircularProgress';
import Close from '@mui/icons-material/Close';
import Divider from '@mui/material/Divider';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import IconButton from '@mui/material/IconButton';
import { LimitCartDialog } from '@components';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import classNames from 'classnames';
import dayjs from 'dayjs';
import has from 'lodash/has';
import isEqual from 'lodash/isEqual';
import isNil from 'lodash/isNil';
import reduce from 'lodash/reduce';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';

export default function DrawerAddToCart({
  open,
  product,
  onClose,
  onOpen = () => null,
  type = 'site',
  forcingDate,
  minDate,
  skipSuggestSiteForService = false,
  isAudioguide = false,
}) {
  // Data
  const { slug } = product;
  const { push, locale } = useRouter();
  const dispatch = useDispatch();
  const { logged } = useSelector(userSelector);
  const { t } = useTranslation('common');

  // State
  const [cart, setCart] = useState([]);
  const [hour, setHour] = useState(null);
  const [tickets, setTickets] = useState([]);
  const [submit, setSubmit] = useState(false);
  const [isTicketsSuccess, setIsTicketsSuccess] = useState(false);
  const [isTicketsLoading, setIsTicketsLoading] = useState(true);
  const [date, setDate] = useState(forcingDate || new Date());
  const [fullPrice, setFullPrice] = useState(0);
  const [activeStep, setActiveStep] = useState(0);
  const [showAlert, setShowAlert] = useState(false);
  const [skipCrossSelling, setSkipCrossSelling] = useState(
    !skipSuggestSiteForService,
  );
  const [maxQuantityAllowed, setMaxQuantityAllowed] = useState(6);
  const [error, setError] = useState(null);

  // Events
  const onClickCart = () => push('/cart');
  const onCloseMaxAddAlert = () => setShowAlert(false);
  const onShowMaxAddAlert = () => setShowAlert(true);
  const onResetStep = () => setActiveStep(null);
  const onSkipCrossSelling = () => setSkipCrossSelling(true);

  const onBackToLastStep = () => {
    setFullPrice(0);
    setCart([]);
    setIsTicketsLoading(false);
    setIsTicketsSuccess(false);
    setActiveStep(0);
    setSkipCrossSelling(true);
    setTickets([]);
    setError(false);
  };

  // Utils
  const steps = [
    {
      label: t('drawers.book_product.steps.day.name'),
    },
    {
      label: t('drawers.book_product.steps.hour.name'),
    },
    {
      label: t('drawers.book_product.steps.tickets.name'),
    },
  ];

  // POST CART
  const [setCartMutation, { isLoading: isAddToCartLoading }] =
    useSetCartMutation();

  // GET TICKETS BY DATE + HOUR to check disponibilità
  const [getTicketsByDateAndHour] =
    useLazyGetTicketsByDateAndHourQuery();

  const [getServiceTicketsByDateAndHour] =
    useLazyGetServiceTicketsByDateAndHourQuery();
  const { data: audioguides = [], isSuccess: isAudioSuccess } =
    useGetServiceTicketsQuery({ slug }, { skip: !isAudioguide });
  // GET RELATED TOUR SITE: cross selling
  const {
    data: tour,
    isSuccess: isTourSuccess,
    isLoading: isTourLoading,
  } = useGetSiteTourBySlugQuery(
    {
      slug,
      hour,
      date: dayjs(date).format('YYYY-MM-DD'),
      locale,
    },
    { skip: hour == null && type === 'service' },
  );
  useEffect(() => {
    if (forcingDate !== null) {
      setDate(forcingDate);
      setActiveStep(1);
    } else {
      setActiveStep(0);
      setDate(new Date());
    }
  }, [forcingDate]);

  // OPEN/CLOSE DRAWER
  useEffect(() => {
    toggleBlurLayout(['blur-md'], open);

    return () => {
      if (!forcingDate) {
        setHour(null);
        setFullPrice(0);
        setActiveStep(0);
        setDate(new Date());
        setCart([]);
        setSkipCrossSelling(true);
        setSubmit(false);
        setIsTicketsSuccess(false);
        setError(false);
      }
    };
  }, [open]);

  useEffect(() => {
    return () => {
      setHour(null);
      setFullPrice(0);
      setActiveStep(0);
      setDate(new Date());
      setCart([]);
      setSkipCrossSelling(true);
      setSubmit(false);
      setIsTicketsSuccess(false);
      setError(false);
    };
  }, []);

  // QUANTITY & FULL PRICE
  useEffect(() => {
    const hasNoStep = isNil(activeStep);
    const hasNoCart = cart?.length === 0;
    const hasDateAndHour = date && hour;

    if (hasNoStep && hasDateAndHour && hasNoCart) {
      setActiveStep(2);
    }

    setFullPrice(
      reduce(
        cart,
        (sum, { price_sale, qty }) => sum + price_sale * qty,
        0,
      ),
    );
  }, [cart]);

  // SHOW CROSS SELLING STEP
  useEffect(() => {
    const isSiteType = type === 'site';
    const hasTours = tour?.length > 0;
    if (isSiteType && skipCrossSelling && hasTours) {
      setSkipCrossSelling(false);
    }
  }, [tour]);

  useEffect(() => {
    const isServiceType = type === 'service';
    const hasSiteSlug = product?.site?.data?.attributes?.slug;

    if (
      !skipSuggestSiteForService &&
      isServiceType &&
      skipCrossSelling &&
      hasSiteSlug &&
      date &&
      hour &&
      cart?.length > 0 &&
      activeStep !== 2
    ) {
      setSkipCrossSelling(false);

      handleGetTicketsSite({
        slug: hasSiteSlug,
        hour,
        date: dayjs(date).format('YYYY-MM-DD'),
      });
    }
  }, [
    cart,
    type,
    date,
    hour,
    product,
    activeStep,
    skipSuggestSiteForService,
  ]);

  useEffect(() => {
    if (date && hour && type) {
      const isSiteType = type === 'site';
      const isServiceType = type === 'service';
      const formattedDate = dayjs(date).format('YYYY-MM-DD');

      const params = {
        slug,
        hour,
        date: formattedDate,
      };
      if (isSiteType) handleGetTicketsSite(params);
      if (isServiceType) handleGetTicketsService(params);
    }
  }, [date, hour, type]);

  // On Get Tickets Site
  const handleGetTicketsSite = async (params) => {
    try {
      setIsTicketsLoading(true);
      const { isSuccess, data, isError } =
        await getTicketsByDateAndHour({
          ...params,
          locale,
        });
      isSuccess & setTickets(data);
      setIsTicketsSuccess(isSuccess);
      setIsTicketsLoading(false);

      return { isSuccess, data, isError };
    } catch (error) {
      console.error('handleGetTicketsSite', error);
      setIsTicketsLoading(false);
    }
  };

  // On Get Service Tickets
  const handleGetTicketsService = async (params) => {
    try {
      setIsTicketsLoading(true);
      if (isAudioguide) {
        setTickets(audioguides);
        setIsTicketsSuccess(isAudioSuccess);
        setIsTicketsLoading(false);
      } else {
        const { isSuccess, data } =
          await getServiceTicketsByDateAndHour({
            ...params,
            locale,
          });

        if (isSuccess) {
          setTickets(data);
          setIsTicketsSuccess(isSuccess);
          setIsTicketsLoading(false);
        }
      }
    } catch (error) {
      setIsTicketsLoading(false);
      setIsTicketsSuccess(false);
      console.error('handleGetTicketsService', error);
    }
  };

  // ON NEXT STEP
  const handleNext = () => {
    try {
      const totalSteps = steps?.length - 1;
      if (activeStep < totalSteps) {
        setActiveStep(activeStep + 1);
      }
    } catch (error) {
      console.error('handleNext', error);
    }
  };

  // ON PREVIOUS STEP
  const handleBack = () => {
    try {
      if (activeStep <= 0) return;

      if (activeStep === 2) {
        setFullPrice(0);
        setCart([]);
      }

      setActiveStep((prevStep) => prevStep - 1);
      setSkipCrossSelling(true);
      setIsTicketsLoading(false);
      setIsTicketsSuccess(false);
      setError(false);
    } catch (error) {
      console.error('handleBack', error);
    }
  };

  // ON SAVE CART
  const onSaveCart = async () => {
    try {
      if (logged) {
        // Add to server cart
        const products = shapeCartServerSide({
          cart,
          date,
          hour,
        });

        const { data } = await setCartMutation({
          products,
        });

        if (data?.success && data?.status === 'success') {
          setSubmit(true);
        } else {
          console.error('onSaveCart FAIL ⚠️');
          setError(true);
          setSubmit(false);
        }
      } else {
        // Add to client cart: DA FARE MEGLIO
        const formattedCart = shapeCartClientSide({
          cart,
          date,
          hour,
          product: {
            name: product?.name || product?.hero?.title,
            city: product?.city?.data?.attributes?.name,
            address: product?.address,
            region: product?.region,
            canonical_name: product?.slug,
            in_concession: cart?.[0]?.in_concession,
            category: cart?.[0]?.product_category,
            // Ramiro ficcati le mani in culo
            site: has(product, 'site.data.attributes')
              ? {
                  name:
                    product?.site.data.attributes?.name ||
                    product?.site.data.attributes?.hero?.title,
                  city: product?.site.data.attributes?.city?.data
                    ?.attributes?.name,
                  address: product?.site.data.attributes?.address,
                  region: product?.site.data.attributes?.region,
                  canonical_name:
                    product?.site.data.attributes?.slug,
                  in_concession: cart?.[0]?.in_concession,
                  // category: cart?.[0]?.category,
                }
              : {
                  name: product?.name || product?.hero?.title,
                  city: product?.city?.data?.attributes?.name,
                  address: product?.address,
                  region: product?.region,
                  canonical_name: product?.slug,
                  in_concession: cart?.[0]?.in_concession,
                  // category: cart?.[0]?.category,
                },
          },
        }).map((p, i) => ({
          ...p,
          id: Date.now() + i,
        }));

        dispatch(setCartReducer(formattedCart));
        setSubmit(true);
      }
    } catch (error) {
      console.error('onSaveCart', error);
      setSubmit(true);
    }
  };

  // ON CHANGE DATA
  const onChangeDate = (date) => {
    try {
      setDate(date);
      setHour(null);
      setFullPrice(0);
      handleNext(false);
      setIsTicketsLoading(false);
      setIsTicketsSuccess(false);
    } catch (error) {
      console.error('onChangeDate', error);
    }
  };

  // ON CLICK SLOT
  const onClickSlot = (hour) => {
    try {
      if (hour) {
        setHour(hour);
        setFullPrice(0);
        handleNext(false);
      }
    } catch (error) {
      console.error('onClickSlot', error);
    }
  };

  const onSetDrawerLabel = () => {
    try {
      const langPath = 'drawers.book_product.steps.';

      switch (activeStep) {
        case 0:
          return t(langPath + 'day.chip');
        case 1:
          return t(langPath + 'hour.chip');
        case 2:
          return t(langPath + 'tickets.chip');
        default:
          if (submit) {
            return error
              ? 'Error'
              : t(langPath + 'congratulations.chip');
          }

          return skipCrossSelling
            ? t(langPath + 'add_to_cart.chip')
            : t(langPath + 'see_also.chip');
      }
    } catch (error) {
      console.error('onSetDrawerLabel', error);
      return '';
    }
  };

  const renderActiveSteps = () => {
    switch (activeStep) {
      case 0:
        return (
          <Calendar
            date={date}
            minDate={minDate}
            onChangeDate={onChangeDate}
          />
        );
      case 1:
        if (type === 'site') {
          return (
            <SiteSlots
              slug={slug}
              onClickSlot={onClickSlot}
              date={dayjs(date).format('YYYY-MM-DD')}
            />
          );
        } else if (type === 'service') {
          return (
            <ServiceSlots
              slug={slug}
              onClickSlot={onClickSlot}
              date={dayjs(date).format('YYYY-MM-DD')}
            />
          );
        }
        break;
      case 2:
        return (
          <Tickets
            tickets={tickets}
            currentCart={cart}
            fullPrice={fullPrice}
            setFullPrice={setFullPrice}
            onChange={(t) => {
              if (!isEqual(cart, t)) setCart(t);
            }}
            isTicketsSuccess={isTicketsSuccess}
            isTicketsLoading={isTicketsLoading}
            onLimitReachedCallback={onShowMaxAddAlert}
            setMaxQuantityAllowed={setMaxQuantityAllowed}
          />
        );
      default:
        return null;
    }
  };

  const renderLoading = (type) =>
    type === 'site' &&
    (skipCrossSelling || !skipCrossSelling) &&
    isTourLoading && (
      <div
        key="loading"
        className={classNames(
          'absolute left-1/2 top-1/2',
          '-translate-x-1/2 -translate-y-1/2 transform',
          'bg-zinc-100 dark:bg-zinc-800',
          'w-11/12',
          'flex items-center justify-center',
        )}
      >
        <CircularProgress size={20} />
      </div>
    );

  const CrossSellingSite = () =>
    type === 'site' &&
    !skipCrossSelling &&
    isTourSuccess &&
    tour?.length > 0 && (
      <div
        className="mt-6 space-y-4 text-gray-700 dark:text-white"
        key="cross-selling-site"
      >
        <h3 className="text-lg font-semibold uppercase">
          {t('drawers.book_product.steps.see_also.title')}
        </h3>
        <Tickets
          tickets={tour}
          currentCart={cart}
          fullPrice={fullPrice}
          setFullPrice={setFullPrice}
          hour={hour}
          onChange={(t) => {
            if (!isEqual(cart, t)) setCart(t);
          }}
          isTicketsSuccess={isTourSuccess}
          isTicketsLoading={isTicketsLoading}
          defaultSlot={tour[0]?.service?.slots[0]}
          onLimitReachedCallback={onShowMaxAddAlert}
          setMaxQuantityAllowed={setMaxQuantityAllowed}
        />
      </div>
    );

  const CrossSellingService = () =>
    type === 'service' &&
    isTicketsSuccess &&
    tickets?.length > 0 &&
    !skipCrossSelling && (
      <div
        className="mt-6 space-y-4 text-gray-700 dark:text-white"
        key="cross-selling-service"
      >
        <div className="space-y-1">
          <h3 className="flex items-center text-lg font-semibold uppercase">
            <ErrorOutlineIcon fontSize="small" className="mr-2" />
            {t('generic.labels.access_not_included')}
          </h3>
          <div className="flex items-center w-full space-x-1">
            <span className="text-sm">
              {t('generic.labels.buy_access')}
            </span>
            <span className="font-medium">
              {product.site?.data?.attributes?.name}
            </span>
          </div>
        </div>
        <Tickets
          tickets={tickets}
          currentCart={cart}
          fullPrice={fullPrice}
          hour={hour}
          setFullPrice={setFullPrice}
          onChange={(t) => {
            if (!isEqual(cart, t)) setCart(t);
          }}
          isTicketsSuccess={isTicketsSuccess}
          isTicketsLoading={isTicketsLoading}
          onLimitReachedCallback={onShowMaxAddAlert}
          setMaxQuantityAllowed={setMaxQuantityAllowed}
        />
      </div>
    );

  const ShowRecap = (type) =>
    (type === 'site' || type === 'service') &&
    isTourSuccess &&
    skipCrossSelling &&
    cart?.length > 0 && (
      <Recap
        key="recap"
        hour={hour}
        date={date}
        cart={cart}
        product={product}
        setCart={setCart}
        fullPrice={fullPrice}
      />
    );
  useEffect(() => {
    if (type === 'service' && tickets?.length <= 0) {
      setSkipCrossSelling(true);
    }
  }, [tickets]);
  return (
    <>
      <SwipeableDrawer
        open={open}
        elevation={0}
        anchor="right"
        onOpen={onOpen}
        onClose={onClose}
        variant="temporary"
        hideBackdrop={false}
        disableSwipeToOpen={true}
        ModalProps={{ keepMounted: false }}
        transitionDuration={{ enter: 200, exit: 300 }}
        sx={{
          '& .MuiDrawer-paper': {
            background: 'none',
            border: 'none',
          },
        }}
      >
        <div
          className={classNames(
            'relative',
            'flex flex-col',
            'select-none',
            'h-screen w-screen md:w-[650px] 3xl:w-[800px]',
            'bg-zinc-100 dark:bg-zinc-800',
            'z-100 overflow-hidden shadow-2xl',
            'px-4 lg:px-6',
          )}
        >
          <div className="h-screen">
            {/* <code>
              <pre>
                {JSON.stringify(
                  {
                    type,
                    isTourLoading,
                    isTourSuccess,
                    isTicketsSuccess,
                    isTicketsLoading,
                    activeStep,
                    skipCrossSelling,
                  },
                  null,
                  2,
                )}
              </pre>
            </code> */}

            <div
              className={classNames(
                'relative',
                'w-full h-10',
                'flex items-center justify-center',
              )}
            >
              <IconButton
                color="inherit"
                component="span"
                size="small"
                onClick={onClose}
                className={classNames(
                  'text-xl',
                  'absolute left-0 top-4',
                  'flex items-center justify-center',
                )}
              >
                <Close fontSize="small" />
              </IconButton>
            </div>

            <div className="flex flex-col pb-0 mb-4 md:mb-8">
              <Divider className="font-semibold uppercase text-primary">
                <Chip
                  variant="outlined"
                  className="px-6 text-xs font-medium w-fit rounded-xl dark:text-primary"
                  label={onSetDrawerLabel()}
                />
              </Divider>
            </div>

            {!isNil(activeStep) && (
              <div className="w-full mx-auto">
                {renderActiveSteps()}
              </div>
            )}

            {isNil(activeStep) && !submit && !error && (
              <>
                {renderLoading(type)}
                {CrossSellingSite()}
                {CrossSellingService()}
                {ShowRecap(type)}
              </>
            )}

            {/* POST ADD TO CART MESSAGE */}
            {submit && !error && (
              <div className="mt-6">
                <div className="flex flex-col items-center justify-center w-full spacey-3">
                  <Alert
                    severity="success"
                    className="w-full bg-transparent"
                  >
                    <AlertTitle className="text-xl">
                      {t(
                        'drawers.book_product.steps.congratulations.title',
                      )}
                    </AlertTitle>
                    <ul>
                      <li>
                        {t(
                          'drawers.book_product.steps.congratulations.timing',
                        )}
                      </li>
                    </ul>
                  </Alert>
                  <Button
                    fullWidth
                    color="success"
                    key="payment"
                    className="max-w-sm mx-auto mt-4"
                    variant="outlined"
                    onClick={onClickCart}
                  >
                    {t('buttons.proceed_payment')}
                  </Button>
                </div>
              </div>
            )}

            {!submit && error && (
              <div className="mt-6">
                <div className="flex flex-col items-center justify-center w-full spacey-3">
                  <Alert
                    severity="error"
                    className="w-full bg-transparent"
                    action={
                      <Button
                        size="small"
                        color="error"
                        className="hidden px-4 md:flex"
                        onClick={onBackToLastStep}
                      >
                        Reset
                      </Button>
                    }
                  >
                    <AlertTitle className="text-xl">
                      Error
                    </AlertTitle>
                    <ul>
                      <li>Please, try again</li>
                    </ul>
                  </Alert>
                </div>
              </div>
            )}

            {/* BUTTONS */}
            <div
              className={classNames(
                'h-12 w-full md:h-14',
                'flex flex-row z-50',
                'bg-inherit',
                'absolute bottom-0 left-0 right-0',
              )}
            >
              <Actions
                activeStep={activeStep}
                cart={cart}
                error={error}
                handleBack={handleBack}
                isAddToCartLoading={isAddToCartLoading}
                isTicketsSuccess={isTicketsSuccess}
                isTourSuccess={isTourSuccess}
                onBackToLastStep={onBackToLastStep}
                onClickCart={onClickCart}
                onResetStep={onResetStep}
                onSaveCart={onSaveCart}
                onSkipCrossSelling={onSkipCrossSelling}
                skipCrossSelling={skipCrossSelling}
                skipSuggestSiteForService={
                  skipSuggestSiteForService
                }
                submit={submit}
                tickets={tickets}
                tour={tour}
                type={type}
              />
            </div>
          </div>
        </div>
      </SwipeableDrawer>

      <LimitCartDialog
        open={showAlert}
        showGoToCart={false}
        onClose={onCloseMaxAddAlert}
        maxQuantityAllowed={maxQuantityAllowed}
        isService={
          isNil(activeStep) && isTourSuccess && tour?.length > 0
        }
      />
    </>
  );
}
