import { FlagEn, FlagIt } from '@icons';

import IconButton from '@mui/material/IconButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';
import classNames from 'classnames';
import { useRouter } from 'next/router';
import useSound from 'use-sound';
import { useState } from 'react';
import { useTranslation } from 'next-i18next';

export default function SwitchLocale() {
  const { locale, pathname, query, push, asPath } = useRouter();
  const { t } = useTranslation('common');
  const isArticleBlogPage = asPath.includes('/blog/');

  const [play, { stop }] = useSound('audio/tick.wav');

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChange = (locale) => {
    try {
      play();
      // Redirect to index blog page in case of page article due to slug issue
      if (isArticleBlogPage) {
        push('/blog', null, { locale });
      } else {
        // For other page switch with all the params
        push({ pathname, query }, asPath, {
          locale,
          scroll: false,
        });
      }
      handleClose();
    } catch (error) {
      console.error('SwitchLocale', error);
    }
  };

  return (
    <>
      <Tooltip title={t('navbar.change_locale')}>
        <IconButton
          className=""
          aria-label="lang"
          id="locale-button"
          aria-haspopup="true"
          onClick={handleClick}
          aria-expanded={open ? 'true' : undefined}
          aria-controls={open ? 'locale-menu' : undefined}
        >
          {locale === 'en' && <FlagEn className="w-5 h-5" />}
          {locale === 'it' && <FlagIt className="w-5 h-5" />}
        </IconButton>
      </Tooltip>

      <Menu
        open={open}
        id="locale-menu"
        anchorEl={anchorEl}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'locale-button',
        }}
      >
        <MenuItem
          className="md:space-x-1"
          onClick={() => handleChange('en')}
          selected={'en' === locale}
        >
          <ListItemIcon>
            <FlagEn className="w-4 h-4" />
          </ListItemIcon>
          <span
            className={classNames('text-sm uppercase', {
              'font-bold': 'en' === locale,
              'opacity-90': 'en' !== locale,
            })}
          >
            {t('navbar.en')}
          </span>
        </MenuItem>
        <MenuItem
          className="md:space-x-1"
          onClick={() => handleChange('it')}
          selected={'it' === locale}
        >
          <ListItemIcon>
            <FlagIt className={classNames('h-4 w-4')} />
          </ListItemIcon>
          <span
            className={classNames('text-sm uppercase', {
              'font-bold': 'it' === locale,
              'opacity-90': 'it' !== locale,
            })}
          >
            {t('navbar.it')}
          </span>
        </MenuItem>
      </Menu>
    </>
  );
}
