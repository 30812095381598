import { useRef, useState } from 'react';
import { scroller } from 'react-scroll';
import Button from '@mui/material/Button';
import ReactMarkdown from 'react-markdown';
import classNames from 'classnames';
import getReadingTime from 'blog-reading-time';
import remarkGfm from 'remark-gfm';
import { useTranslation } from 'next-i18next';

type Paragraph = {
  title?: string;
  content?: string;
};

type DescriptionPros = {
  content?: string;
  paragraphs?: Paragraph[];
  gallery?: [any];
};

export default function Description({ content }: DescriptionPros) {
  const textRef = useRef(null);
  const [expand, setExpand] = useState(false);

  const { t } = useTranslation('common');
  if (content) {
    return (
      <>
        <div
          ref={textRef}
          className={classNames(
            'relative',
            'text-sm md:text-base',
            'text-gray-700 dark:text-white',
          )}
        >
          {content?.length <= 1000 ? (
            <ReactMarkdown
              remarkPlugins={[remarkGfm]}
              className={classNames(
                'md:text-base text-sm',
                'leading-relaxed',
                'strapi-editorial',
              )}
            >
              {content}
            </ReactMarkdown>
          ) : (
            <div
              className={classNames('transition-all ease-in-out', {
                clamp: !expand,
              })}
            >
              <ReactMarkdown
                remarkPlugins={[remarkGfm]}
                className="text-sm strapi-editorial md:text-base"
              >
                {content}
              </ReactMarkdown>
            </div>
          )}
        </div>

        {content?.length > 1000 && (
          <div className="my-8 space-y-2">
            <Button
              size="small"
              variant="contained"
              className={classNames(
                'uppercase',
                'btn-expand-paragraph',
                'flex items-center justify-center',
                'mx-auto w-full md:max-w-xs',
                'bg-primary text-white',
                {
                  open: expand,
                  close: !expand,
                },
              )}
              onClick={() => {
                setExpand(!expand);
                scroller?.scrollTo('description', {
                  offset: -90,
                });
              }}
            >
              {expand ? t('buttons.close') : t('buttons.read_more')}
            </Button>

            {!expand && (
              <span
                className={classNames(
                  'flex justify-center',
                  'w-full opacity-50',
                  'pb-8 md:pb-0',
                  'mx-auto space-x-1',
                  'text-sm text-gray-700 dark:text-white',
                )}
              >
                <span>{t('generic.labels.reading_time')}</span>
                <span>{getReadingTime(content)} min</span>
              </span>
            )}
          </div>
        )}
      </>
    );
  }

  return null;
}
