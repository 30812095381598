import { getCookie, hasCookie, setCookie } from 'cookies-next';
import { useEffect, useRef, useState } from 'react';

import Button from '@mui/material/Button';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useRouter } from 'next/router';

import { useTranslation } from 'next-i18next';

export default function GdprProvider({ children }) {
  const { pathname } = useRouter();
  const descriptionElementRef = useRef(null);
  const [showGdprDialog, setShowGdprDialog] = useState(
    pathname !== '/cookie-policy' && !hasCookie('localConsent'),
  );
  const [consent, setConsent] = useState(true);

  const onCloseGdprDialog = () => {
    setConsent(true);
    setCookie('localConsent', 'true', {
      maxAge: 60 * 60 * 24 * 365,
    });
    setShowGdprDialog(false);
  };

  // i18s
  const { t } = useTranslation('common');

  useEffect(() => {
    const checkCookiePresence = hasCookie('localConsent');
    if (checkCookiePresence) setConsent(getCookie('localConsent'));
    if (!checkCookiePresence && pathname !== '/cookie-policy')
      setShowGdprDialog(true);
  }, [hasCookie('localConsent'), pathname]);

  const acceptCookie = () => {
    setConsent(true);
    setCookie('localConsent', 'true', {
      maxAge: 60 * 60 * 24 * 365,
    });
    setShowGdprDialog(false);
    gtag('consent', 'update', {
      ad_storage: 'granted',
      analytics_storage: 'granted',
    });
    console.info('accepting cookies');
  };

  const denyCookie = () => {
    setConsent(true);
    setShowGdprDialog(false);
    setCookie('localConsent', 'false', {
      maxAge: 60 * 60 * 24 * 365,
    });
    gtag('consent', 'default', {
      ad_storage: 'denied',
      analytics_storage: 'denied',
    });
    console.info('denying cookie');
  };

  return (
    <div>
      {children}
      <Dialog
        open={showGdprDialog}
        onClose={onCloseGdprDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="croll-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {t('dialogs.cookies.title')}
        </DialogTitle>
        <DialogContent dividers={true}>
          <div
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
          >
            <DialogContentText className="space-y-6">
              <span className="text-gray-700 dark:text-white">
                {t('dialogs.cookies.text')}
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.iubenda.com/privacy-policy/18635860/cookie-policy"
                  className="ml-1 text-primary"
                >
                  Cookie Policy
                </a>
                .
              </span>
            </DialogContentText>
          </div>
        </DialogContent>
        <DialogActions className="justify-center md:justify-end">
          <Button
            onClick={denyCookie}
            className="text-sm"
            size="large"
          >
            {t('dialogs.cookies.actions.decline')}
          </Button>
          <Button
            onClick={acceptCookie}
            autoFocus
            className="text-sm"
            size="large"
          >
            {t('dialogs.cookies.actions.accept')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
