import {
  createApi,
  fetchBaseQuery,
} from '@reduxjs/toolkit/query/react';

const baseUrl = process.env.NEXT_PUBLIC_ADITUS;
export const GenericService = createApi({
  reducerPath: 'Generic',
  tagTypes: ['Generic'],
  baseQuery: fetchBaseQuery({
    baseUrl,
    prepareHeaders: (headers, { getState }) => {
      const token = getState().user.access_token;
      if (token) {
        headers.set('Accept', 'application/json');
        headers.set('Authorization', `Bearer ${token}`);
        return headers;
      }

      return headers;
    },
  }),
  onQueryStarted: () => {
    console.info('onQueryStarted Generic');
  },
  endpoints: (builder) => ({
    getIDdocumentTypes: builder.query({
      query: ({ locale = 'it' }) =>
        `/document-types?lang=${locale}`,
      transformResponse: (res) => {
        return res?.data;
      },
    }),
  }),
});

export const { useGetIDdocumentTypesQuery } = GenericService;

export default GenericService;
