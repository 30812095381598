import { cartSelector, userSelector } from '@slices';
import {
  deleteCartReducer,
  logoutReducer,
  setReconciliationReducer,
} from '@slices';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import {
  useGetCartCreationDateQuery,
  useGetCartQuery,
  useSetCartMutation,
} from '@services';

import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Link from 'next/link';
import Slide from '@mui/material/Slide';
import Snackbar from '@mui/material/Snackbar';
import SnackbarContent from '@mui/material/SnackbarContent';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import { jwtDecode } from 'jwt-decode';
import relativeTime from 'dayjs/plugin/relativeTime';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { useMediaQueries } from '@hooks';

dayjs.extend(duration);
dayjs.extend(relativeTime);

const AuthProvider = ({ children }) => {
  const { locale } = useRouter();
  // Redux
  const dispatch = useDispatch();
  const user = useSelector(userSelector);
  const clientCart = useSelector(cartSelector);
  const [showCartReminder, setShowCartReminder] = useState(false);
  // Next hooks
  const [setCartMutation] = useSetCartMutation();

  //i18n
  const { t } = useTranslation('common');
  const { isMobile } = useMediaQueries();
  const onCloseCartReminder = () => setShowCartReminder(false);

  const SlideTransition = (props) => (
    <Slide
      {...props}
      direction={isMobile ? 'down' : 'up'}
      mountOnEnter
      unmountOnExit
    />
  );

  // Redux Rtk
  const {
    data: serverCart,
    isSuccess: isServerCartSuccess,
    isFetching: isServerCartFetching,
  } = useGetCartQuery({ locale }, { skip: !user.logged });

  const { data: creation, isSuccess: isCartCreationDataSuccess } =
    useGetCartCreationDateQuery(null, {
      skip: !user.logged,
    });

  useEffect(() => {
    if (user?.logged && user?.access_token !== null) {
      const decoded = jwtDecode(user?.access_token);
      const isValid =
        new Date(decoded.exp * 1000).getTime() >=
        new Date().getTime();

      if (isValid && user?.logged)
        console.info(
          '%c User Successfully Logged',
          'color: green;',
        );

      if (!isValid) {
        dispatch(logoutReducer());
      }
    }
  }, [user]);

  useEffect(async () => {
    if (
      serverCart?.products?.length > 0 &&
      clientCart?.products?.length > 0
    ) {
      // Delete client cart if server is not empty
      dispatch(deleteCartReducer());
      dispatch(setReconciliationReducer(false));
    }

    // Update server cart (if empty) with client cart
    if (
      isServerCartSuccess &&
      serverCart?.products?.length === 0 &&
      clientCart?.products?.length > 0 &&
      !clientCart?.reconciliation
    ) {
      dispatch(setReconciliationReducer(true));

      await setCartMutation({
        products: clientCart.products.map((p) => ({
          product_id: p.product?.id,
          ...p,
        })),
      });
    }

    // Safety check for this damn reconciliation
    if (!isServerCartSuccess && clientCart?.reconciliation) {
      dispatch(setReconciliationReducer(false));
    }
  }, [
    user,
    serverCart,
    clientCart?.products,
    clientCart?.reconciliation,
  ]);

  useEffect(() => {
    if (
      user?.logged &&
      serverCart?.products?.length > 0 &&
      !showCartReminder &&
      isCartCreationDataSuccess &&
      creation?.data &&
      !isServerCartFetching
    ) {
      setShowCartReminder(true);
    }
  }, [serverCart]);

  return (
    <>
      {user?.logged && (
        <Snackbar
          severity="info"
          role="info"
          open={showCartReminder}
          TransitionComponent={SlideTransition}
          anchorOrigin={{
            vertical: isMobile ? 'top' : 'bottom',
            horizontal: 'center',
          }}
          autoHideDuration={5000}
          onClose={onCloseCartReminder}
          // autoHideDuration={1000}
          // transitionDuration={{ enter: 1000, exit: 2000 }}
          TransitionProps={{
            enter: true,
            exit: false,
          }}
        >
          <SnackbarContent
            className="justify-center w-full text-center bg-primary"
            action={
              <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={onCloseCartReminder}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            }
            message={
              <Link href="/cart" passHref>
                <a className="space-x-1 underline-offset-4 hover:underline">
                  <span>{t('generic.labels.cart_expires')}</span>
                  <span>
                    {dayjs(creation?.data?.created_at)
                      .add('2', 'hour')
                      .from()}
                  </span>
                </a>
              </Link>
            }
          />
        </Snackbar>
      )}
      {children}
    </>
  );
};

export default AuthProvider;
