import {
  createApi,
  fetchBaseQuery,
} from '@reduxjs/toolkit/query/react';

const baseUrl = process.env.NEXT_PUBLIC_STRAPI;

export const StrapiService = createApi({
  reducerPath: 'Strapi',
  tagTypes: ['Strapi'],
  baseQuery: fetchBaseQuery({ baseUrl }),
  onQueryStarted: () => {},
  endpoints: (builder) => ({
    // ADVANTAGES ----------------------------------
    getAdvantages: builder.query({
      providesTags: ['Strapi'],
      query: ({ locale = 'it' }) => `advantages?locale=${locale}`,
      transformResponse: (res, meta, arg) => {
        return res?.data || [];
      },
    }),
    // SITES ----------------------------------
    getStrapiSites: builder.query({
      providesTags: ['Strapi'],
      query: ({ query = null, locale = 'it' }) => {
        const qs = [
          `locale=${locale}`,
          'populate=hero.media',
          'populate=type',
          'populate=img',
          'populate=city',
          'populate=coordinates',
          'populate=facilities.list',
          'populate=itinerants',
          query ?? '',
        ];

        return `sites?${qs.join('&')}`;
      },
      transformResponse: (res, meta, arg) => {
        return res || [];
      },
    }),
    getStrapiSitesTypes: builder.query({
      providesTags: ['Strapi'],
      query: ({ locale = 'it' }) => {
        const qs = [`locale=${locale}`, 'populate[0]=type'];
        return `sites?${qs.join('&')}`;
      },
      transformResponse: (res, meta, arg) => {
        return res?.data || [];
      },
    }),
    getStrapiSiteFiltersTypes: builder.query({
      providesTags: ['Strapi'],
      query: ({ locale = 'it' }) =>
        `site-types?populate=deep&locale=${locale}`,
      transformResponse: (res, meta, arg) => {
        return (
          res?.data.map(({ attributes }) => ({
            name: attributes?.name,
            slug: attributes?.slug,
          })) || []
        );
      },
    }),
    // EVENTS ----------------------------------
    getStrapiTempProducts: builder.query({
      providesTags: ['Strapi'],
      query: ({ query = null, locale = 'it' }) => {
        const qs = [
          `locale=${locale}`,
          'sort=start:desc',
          'populate[3]=hero.poster',
          'populate[2]=hero.media',
          'populate[0]=type',
          'populate=img',
          'populate=city',
          query ?? '',
        ];
        return `temporaries?${qs.join('&')}`;
      },
      transformResponse: (res, meta, arg) => {
        return res;
      },
    }),
    getStrapiTempFiltersTypes: builder.query({
      providesTags: ['Strapi'],
      query: ({ locale = 'it' }) =>
        `temporary-types?populate=deep&locale=${locale}`,
      transformResponse: (res, meta, arg) => {
        return (
          res?.data.map(({ attributes }) => ({
            name: attributes?.name,
            slug: attributes?.slug,
          })) || []
        );
      },
    }),
    // TOURS ----------------------------------
    getStrapiItinerants: builder.query({
      providesTags: ['Strapi'],
      query: ({ query = '', locale = 'it' }) => {
        const qs = [
          // `publicationState=preview`,
          `locale=${locale}`,
          'populate[0]=hero.media',
          'populate[1]=type',
          'populate=img',
          'populate=city',
          'populate=duration',
          'populate=facilities',
          'populate[2]=facilities.list',
          'populate[3]=paths.list',
          'populate=paths',
          'populate=languages',
          query ?? '',
        ];

        return `itinerants?${qs.join('&')}`;
      },
      transformResponse: (res, meta, arg) => {
        return res;
      },
    }),
    getStrapiItinerantFiltersTypes: builder.query({
      providesTags: ['Strapi'],
      query: ({ locale = 'it' }) =>
        `itinerant-types?populate=deep&locale=${locale}`,
      transformResponse: (res, meta, arg) => {
        return (
          res?.data.map(({ attributes }) => ({
            name: attributes?.name,
            slug: attributes?.slug,
          })) || []
        );
      },
    }),
    // CITIES ----------------------------------
    getStrapiCities: builder.query({
      providesTags: ['Strapi'],
      query: ({
        query = null,
        locale = 'it',
        heroMedia = null,
      }) => {
        return `cities?locale=${locale}&${query}&${
          heroMedia ?? ''
        }`;
      },
      transformResponse: (res, meta, arg) => {
        return res?.data || [];
      },
    }),
    getStrapiCitiesCoordinates: builder.query({
      providesTags: ['Strapi'],
      query: ({ locale = 'it' }) => {
        const qs = [
          `locale=${locale}`,
          'populate[0]=coordinates',
          'populate[1]=city',
          'populate[2]=type',
          'populate[3]=img',
        ];
        return `sites?${qs.join('&')}`;
      },
      transformResponse: (res, meta, arg) => {
        return res?.data || [];
      },
    }),
    getArticleCategories: builder.query({
      //providesTags: ['Strapi'],
      query: ({ locale }) => `/article-categories?locale=${locale}`,
      transformResponse: (res, meta, arg) => {
        return res?.data || [];
      },
    }),
    // SCHOOLS ----------------------------------
    getStrapiSchoolProducts: builder.query({
      providesTags: ['Strapi'],
      query: ({ query = null, locale = 'it' }) => {
        const qs = [
          `locale=${locale}`,
          'populate[1]=hero.media',
          'populate[2]=type',
          'populate=img',
          'populate=city',
          'populate[3]=facilities.list',
          'populate=types',
          'populate=site',
          'populate=grades',
          query ?? '',
        ];

        return `school-activities?${qs.join('&')}`;
      },
      transformResponse: (res, meta, arg) => {
        return res || [];
      },
    }),
    getSchoolActivityFiltersTypes: builder.query({
      providesTags: ['Strapi'],
      query: ({ locale = 'it' }) =>
        `school-activity-types?locale=${locale}`,
      transformResponse: (res, meta, arg) => {
        return (
          res?.data.map(({ attributes }) => ({
            name: attributes?.name,
            slug: attributes?.slug,
          })) || []
        );
      },
    }),
    getSchoolGrades: builder.query({
      providesTags: ['Strapi'],
      query: ({ locale = 'it' }) =>
        `school-grades?locale=${locale}`,
      transformResponse: (res, meta, arg) => {
        return (
          res?.data.map(({ attributes }) => ({
            name: attributes?.name,
            slug: attributes?.slug,
          })) || []
        );
      },
    }),
    // OFFER PACKAGES ----------------------------------
    getStrapiOfferPackages: builder.query({
      providesTags: ['Strapi'],
      query: ({ locale = 'it' }) => {
        const params = [
          'populate[0]=offert',
          'populate[1]=offert.site',
          `locale=${locale}`,
        ];
        return `packages?${params.join('&')}`;
      },
      transformResponse: (res, meta, arg) => {
        return res?.data || [];
      },
    }),
  }),
});

export const {
  // Sites queries
  useGetStrapiSitesQuery,
  useGetStrapiSiteFiltersTypesQuery,
  useGetStrapiSitesTypesQuery,
  // Event queries
  useGetStrapiTempProductsQuery,
  useGetStrapiTempFiltersTypesQuery,
  // Tours queries
  useGetStrapiItinerantsQuery,
  useGetStrapiItinerantFiltersTypesQuery,
  // Advantages queries
  useGetAdvantagesQuery,
  // Cities queries
  useGetStrapiCitiesQuery,
  useGetStrapiCitiesCoordinatesQuery,
  // Articles queries
  useGetArticleCategoriesQuery,
  // Schools queries
  useGetStrapiSchoolProductsQuery,
  useGetSchoolActivityFiltersTypesQuery,
  useGetSchoolGradesQuery,
  // Offer Packages
  useGetStrapiOfferPackagesQuery,
} = StrapiService;

export default StrapiService;
