import classNames from 'classnames';
import { City, DarkLayer, Tag, Title, Type } from '@cardElements';
import { useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Skeleton from '@mui/material/Skeleton';
import dayjs from 'dayjs';
import { getCleanedPaths } from '@utils';
import { getDistance } from '@utils';
import truncate from 'lodash/truncate';
import { useStrapiGetImgFormats } from '@hooks';
import { useTranslation } from 'next-i18next';
import Link from 'next/link';

export default function ProductCard({
  product,
  className,
  segment,
  getDistanceFrom = null,
  baseSegment,
}) {
  const {
    hero,
    slug,
    city,
    type,
    tag,
    end,
    start,
    coordinates,
    abstract,
    grades,
    types,
    itinerants,
    temporaries,
    school_activities,
    site,
  } = product?.attributes;

  const isTerminated = start && end && new Date(end) < new Date();
  const isComingSoon = start && new Date(start) > new Date();

  const [loading, setLoading] = useState(true);
  const { t } = useTranslation('common');
  const { small } = useStrapiGetImgFormats({
    img:
      hero?.poster?.data?.attributes ??
      hero?.media?.data?.attributes,
  });

  // Events
  const onLoadMedia = () => setLoading(false);

  // Utils
  const {
    cityPath,
    prodPath,
    baseSegment: base,
    typeSegment,
  } = getCleanedPaths({
    slug,
    typeSegment: segment,
    city: city?.data?.attributes?.slug,
    baseSegment,
  });

  const showDistanceFrom =
    getDistanceFrom && coordinates
      ? getDistance(getDistanceFrom, coordinates)
      : false;

  // Activities counter (event + tour + school activities)
  const totalActivities =
    itinerants?.data?.length +
    temporaries?.data.filter((t) => new Date() < new Date(t?.end))
      ?.length +
    school_activities?.data?.length;

  return (
    <div
      className={classNames(
        className,
        'h-100 p-4 md:p-6 xl:p-8',
        'overflow-hidden rounded-md',
        'default-card group relative',
        'flex w-full flex-col justify-end',
        'bg-slate-100 dark:bg-zinc-800',
      )}
    >
      {totalActivities > 0 && (
        <div
          className={classNames(
            'absolute top-0 left-0',
            'rounded-br-sm',
            'text-white opacity-90',
            'z-20 space-x-1 text-sm italic',
            'flex items-center',
            'px-5',
            'font-thin text-white',
            'bg-white/40 backdrop-blur-lg',
            '-translate-y-10',
            'group-hover:translate-y-0',
            //'transform-gpu',
            'transition-all duration-200 ease-in-out',
          )}
        >
          <span className="text-base font-bold">
            {totalActivities}
          </span>
          <span>{t('generic.labels.activities')}</span>
        </div>
      )}

      {loading && (
        <Skeleton
          animation="wave"
          height={'100%'}
          width={'100%'}
          variant="rectangular"
          className="absolute inset-0 z-50 w-full h-full"
        />
      )}

      <Tag tag={tag || hero?.label} />

      <div
        className={classNames(
          'text-sm text-white',
          'z-10 w-full space-y-1.5',
          'flex flex-col items-start justify-between',
        )}
      >
        <Type
          {...type?.data?.attributes}
          slug={type?.data?.attributes?.slug}
          type={type?.data?.attributes?.name}
          pathname={base + typeSegment}
          query={type?.data?.attributes?.slug}
        />

        {site?.data?.attributes && (
          <Link
            href={{
              pathname:
                '/esperienze/[city]/musei-parchi-archeologici/[slug]',
              query: {
                city: city?.data?.attributes?.slug,
                slug: site?.data?.attributes?.slug,
              },
            }}
            passHref
          >
            <a
              className={classNames(
                'underline-offset-2 hover:underline',
                'py-1 md:py-0.5',
                'text-white',
                'inline-flex items-center font-condensed',
                'text-xs uppercase tracking-wider',
                'bg-primary dark:bg-primary-400',
                'px-2.5 py-1 md:py-0.5',
              )}
            >
              {site?.data?.attributes?.name}
            </a>
          </Link>
        )}

        <Title href={prodPath} title={hero?.title} />

        {/* Short intro */}
        {abstract && (
          <p
            className={classNames(
              'hidden lg:flex',
              'opacity-80 h-0 overflow-hidden',
              'text-base 2xl:text-lg',
              'group-hover:h-18',
              'transform-gpu',
              'transition-all duration-300 ease-in-out',
            )}
          >
            {truncate(abstract, { length: 90, separator: /,? +/ })}
          </p>
        )}
        <span
          className={classNames(
            'w-full space-x-2',
            'flex flex-row items-center justify-between ',
          )}
        >
          {city?.data?.attributes?.slug && (
            <City
              href={cityPath}
              city={city?.data?.attributes?.name}
            />
          )}

          {/* SHOW DISTANCE FROM ONE SITE */}
          {showDistanceFrom && showDistanceFrom < 40 && (
            <span className="flex p-1 text-base">
              <span className="italic">
                {t('generic.labels.less_than')}
              </span>
              <span className="mx-1 italic font-semibold">
                {showDistanceFrom} km
              </span>
            </span>
          )}

          {/* Event terminated */}
          {isTerminated && (
            <span className="font-medium">
              {t('generic.labels.terminated')}
            </span>
          )}

          {/* Show coming soon */}
          {isComingSoon && (
            <span className="space-x-2">
              <span>{t('generic.labels.start')}</span>
              <span className="font-semibold">
                {dayjs(start).format('DD MMMM YYYY')}
              </span>
            </span>
          )}

          {/* Show End Date */}
          {!isTerminated && start && end && !isComingSoon && (
            <span className="space-x-2">
              <span>{t('generic.labels.until')}</span>
              <span className="font-semibold">
                {dayjs(end).format('DD MMMM YYYY')}
              </span>
            </span>
          )}
        </span>
      </div>
      <DarkLayer />

      <LazyLoadImage
        alt=""
        height="auto"
        effect="opacity"
        width={small?.width}
        src={small?.url}
        afterLoad={onLoadMedia}
        className={classNames(
          'absolute',
          'brightness-90',
          'inset-0 z-0 h-full w-full',
          'object-cover object-center',
          'group-hover:scale-110 group-hover:brightness-50',
          'transition-all duration-500 ease-in-out',
        )}
      />
    </div>
  );
}
