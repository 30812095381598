import { createSelector, createSlice } from '@reduxjs/toolkit';

import take from 'lodash/take';
import uniqBy from 'lodash/uniqBy';

const initialState = {
  logged: false,
  showLoginDialog: false,
  reductionsIDs: [],
  productsReminder: [],
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    logoutReducer: (state) => {
      return {
        ...initialState,
        productsReminder: state?.productsReminder,
      };
    },
    loginReducer: (state, { payload }) => {
      return {
        logged: true,
        ...payload?.user,
        access_token: payload?.access_token,
        expires_in: payload?.expires_in,
      };
    },
    setUserReducer: (state, { payload }) => {
      return { ...state, ...payload };
    },
    showLoginDialogReducer: (state, { payload }) => {
      return { ...state, showLoginDialog: payload };
    },
    setReductionsIDsReducer: (state, { payload }) => {
      if (state?.logged)
        return { ...state, reductionsIDs: payload };
    },
    setProductsReminderReducer: (state, { payload }) => {
      const oldOnes =
        state?.productsReminder?.length > 0
          ? state.productsReminder
          : [];
      return {
        ...state,
        productsReminder: take(
          uniqBy([payload, ...oldOnes], 'slug'),
          3,
        ),
      };
    },
    resetProductsReminderReducer: (state) => {
      return {
        ...state,
        productsReminder: [],
      };
    },
  },
  //extraReducers: {},
});

export const {
  logoutReducer,
  setUserReducer,
  loginReducer,
  showLoginDialogReducer,
  setReductionsIDsReducer,
  setProductsReminderReducer,
  resetProductsReminderReducer,
} = userSlice.actions;

export const userSelector = createSelector(
  ({ user }) => user,
  (user) => user,
);

export default userSlice.reducer;
