import * as React from 'react';

import { useEffect, useState } from 'react';

import { AddIdentityDocument } from '@components';
import AppBar from '@mui/material/AppBar';
import CloseIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import dayjs from 'dayjs';
import { setReductionsIDsReducer } from '@slices';
import { useDispatch } from 'react-redux';
import { useGetIDdocumentTypesQuery } from '@services';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { useMediaQueries } from '@hooks';

export default function IDsDialog({
  open,
  cart,
  onClose,
  totalRequired,
  setAllowPayment,
}) {
  const [documentsProvided, setDocumentsProvided] = useState([]);
  const { isMobile } = useMediaQueries();
  const dispatch = useDispatch();
  const { locale } = useRouter();
  const { data: types, isSuccess } = useGetIDdocumentTypesQuery({
    locale,
  });

  const { t } = useTranslation('common');

  useEffect(() => {
    if (
      cart?.length > 0 &&
      documentsProvided?.length === totalRequired
    ) {
      dispatch(
        setReductionsIDsReducer(
          documentsProvided.map((o) => {
            const ob = {};
            for (const [key, value] of Object.entries(o)) {
              if (key == 'document_expire_at') {
                ob[key] = dayjs(value.value).year();
              } else ob[key] = value.value;
            }
            return ob;
          }),
        ),
      );
      setAllowPayment();
      onClose();
    }
  }, [documentsProvided]);

  return (
    <Dialog
      fullWidth
      open={open}
      maxWidth="md"
      onClose={onClose}
      fullScreen={isMobile}
    >
      <AppBar className="relative text-gray-700 bg-slate-100 dark:bg-black dark:text-white">
        <Toolbar className="relative flex p-1 md:justify-center">
          <h3 className="ml-6 text-sm md:text-lg">
            {t('forms.reductions.title')}
          </h3>

          <IconButton
            edge="start"
            color="white"
            fontSize="small"
            onClick={onClose}
            aria-label="close"
            className="absolute right-2"
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </Toolbar>
      </AppBar>

      <DialogContent className="relative p-5 pb-2">
        <p className="relative mb-5 text-sm">
          {t('forms.reductions.info')}
        </p>

        <main>
          {isSuccess &&
            types?.length > 0 &&
            cart?.length > 0 &&
            cart.map(({ product }, i) => {
              const { reductions, site, name, id } = product;

              return (
                <AddIdentityDocument
                  key={i}
                  id={id}
                  site={site}
                  name={name}
                  reductions={reductions}
                  types={types}
                  className={`${
                    documentsProvided.length === i ? '' : 'hidden'
                  }`}
                  onSuccess={(item) =>
                    setDocumentsProvided([
                      ...documentsProvided,
                      item,
                    ])
                  }
                />
              );
            })}

          {documentsProvided?.length < totalRequired && (
            <div className="flex justify-end px-1 mt-2 space-x-3">
              <span className="">
                {documentsProvided?.length + 1}
              </span>
              <span>/</span>
              <span className="font-semibold">{totalRequired}</span>
            </div>
          )}
        </main>
      </DialogContent>
    </Dialog>
  );
}
