import { useCallback, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';

import CancelIcon from '@mui/icons-material/Cancel';
import TextField from '@mui/material/TextField';
import isFunction from 'lodash/isFunction';
import omit from 'lodash/omit';
import { useTranslation } from 'next-i18next';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { IconButton } from '@mui/material';

export default function CustomThemeInput(props) {
  const [inputValue, setInputValue] = useState(
    props?.defaultValue || '',
  );
  const [validation, setValidation] = useState(false);
  const inputRef = useRef(null);

  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () =>
    setShowPassword((show) => !show);

  // i18n
  const { t } = useTranslation('common');

  const checkRegexPattern = useCallback(
    () =>
      props?.regex != null ? props.regex?.test(inputValue) : true,
    [inputValue],
  );

  useEffect(() => {
    if (props?.onSetValue && isFunction(props.onSetValue)) {
      props.onSetValue({
        value: inputValue,
        valid: inputValue?.length > 0 && checkRegexPattern(),
      });
    }
  }, [inputValue, props.toCompare, checkRegexPattern]);

  return (
    <TextField
      fullWidth
      {...omit(props, [
        'reset',
        'onSetValue',
        'defaultValue',
        'customCondition',
        'toCompare',
      ])}
      margin="dense"
      size={props?.size || 'small'}
      autoComplete="off"
      multiline={props?.multiline}
      rows={props?.rows}
      // defaultValue={props?.defaultValue}
      placeholder={props?.placeholder || null}
      value={inputValue}
      className={classNames(
        'rounded-none font-primary',
        props?.className,
      )}
      ref={inputRef}
      onChange={({ target }) => setInputValue(target.value)}
      onBlur={() => {
        inputValue?.length > 0
          ? setValidation(
              !checkRegexPattern() &&
                (!props?.customCondition?.(
                  inputValue,
                  props?.toCompare,
                ) ||
                  false),
            )
          : setValidation(true);
      }}
      error={validation}
      helperText={
        validation &&
        ((inputValue?.length > 0 && props?.helperText) ||
          t('forms.validations.invalid_input'))
      }
      inputProps={{
        type:
          !showPassword && props?.type === 'password'
            ? 'password'
            : 'text' || (props?.type !== 'password' && props?.type),
        maxLength: props?.maxLength ?? 50,
        minLength: props?.minLength ?? 3,
      }}
      InputProps={{
        endAdornment: (
          <>
            {props?.reset &&
              inputValue?.length >= (props?.minLength ?? 2) &&
              props?.type !== 'password' && (
                <CancelIcon
                  className="w-5 h-5 ml-5 cursor-pointer fill-primary"
                  onClick={() => {
                    inputRef.current.value = '';
                    setInputValue('');
                  }}
                />
              )}
            {props?.type === 'password' &&
              inputValue?.length >= 1 && (
                <IconButton
                  onClick={handleClickShowPassword}
                  // onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword ? (
                    <VisibilityOff color="primary" />
                  ) : (
                    <Visibility color="primary" />
                  )}
                </IconButton>
              )}
          </>
        ),
      }}
    />
  );
}
