import { hasLetterAndNumber, validPassword } from '@utils';
import { useEffect, useState } from 'react';
import {
  useGetUserDataQuery,
  useUpdateUserDataMutation,
} from '@services';

import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Button from '@mui/material/Button';
import { CustomTextInput } from '@components';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import LoadingButton from '@mui/lab/LoadingButton';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import classNames from 'classnames';
import every from 'lodash/every';
import { logoutReducer } from '@slices';
import { useDispatch } from 'react-redux';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';

export default function UpdatedUserProfileForm({
  id,
  className,
  onError,
  onCancel,
}) {
  const dispatch = useDispatch();
  const { push, locale } = useRouter();
  const { t } = useTranslation('common');

  const [allowSubmit, setAllowSubmit] = useState(false);
  const { data: user } = useGetUserDataQuery(id);
  const [updateUserData, response] = useUpdateUserDataMutation();
  // Form Values
  const [formValues, setFormValues] = useState({
    first_name: user?.first_name,
    last_name: user?.last_name,
    old_password: { value: null, valid: false },
    new_password: { value: null, valid: false },
    new_password_confirmation: {
      value: null,
      valid: false,
    },
    language_code: { value: locale, valid: true },
    newsletter: {
      value: user?.newsletter,
      valid: true,
    },
    profilation: {
      value: user?.profilation || false,
      valid: true,
    },
  });

  const onSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    try {
      const data = {};

      Object.keys(formValues).map((k) => {
        const hasKey = formValues[k]?.hasOwnProperty('value');
        const val = hasKey ? formValues[k].value : formValues[k];
        data[k] = val;
      });

      const res = await updateUserData({ id, data });
      if (res?.success) {
        dispatch(logoutReducer());
        push('/');
      }
    } catch (error) {
      console.error(error);
      onError && onError();
    }
  };

  useEffect(() => {
    setAllowSubmit(every(formValues, 'valid'));
  }, [formValues]);

  return (
    <>
      {!response?.data && (
        <form
          className={['space-y-6', className].join(' ')}
          onSubmit={onSubmit}
        >
          <CustomTextInput
            defaultValue={formValues.first_name}
            placeholder={formValues.first_name}
            required
            fullWidth
            type="text"
            reset={true}
            margin="dense"
            className="my-1"
            name="first_name"
            label={t('forms.name')}
            variant="outlined"
            regex={hasLetterAndNumber}
            helperText={t(
              'forms.validations.no_special_characters',
            )}
            onSetValue={(value) =>
              setFormValues({
                ...formValues,
                first_name: value,
              })
            }
          />

          <CustomTextInput
            defaultValue={formValues.last_name}
            placeholder={formValues.last_name}
            required
            fullWidth
            type="text"
            reset={true}
            margin="dense"
            className="my-1"
            name="last_name"
            label={t('forms.surname')}
            variant="outlined"
            regex={hasLetterAndNumber}
            helperText={t(
              'forms.validations.no_special_characters',
            )}
            onSetValue={(value) =>
              setFormValues({
                ...formValues,
                last_name: value,
              })
            }
          />

          <CustomTextInput
            required
            fullWidth
            reset={true}
            margin="dense"
            className="my-1"
            type="password"
            name="old_password"
            label={t('forms.old_password')}
            variant="outlined"
            regex={validPassword}
            customCondition={(x, y) => x !== y}
            toCompare={formValues?.new_password.value}
            autoComplete="new-password"
            helperText={t('forms.validations.password_rules')}
            onSetValue={(value) =>
              setFormValues({
                ...formValues,
                old_password: value,
              })
            }
          />

          <CustomTextInput
            required
            fullWidth
            reset={true}
            margin="dense"
            className="my-1"
            type="password"
            name="new_password"
            label={t('forms.new_password')}
            variant="outlined"
            regex={validPassword}
            autoComplete="new-password"
            customCondition={(x, y) => x !== y}
            toCompare={formValues?.old_password.value}
            helperText={t('forms.validations.password_rules')}
            onSetValue={(value) =>
              setFormValues({
                ...formValues,
                new_password: value,
              })
            }
          />

          <CustomTextInput
            required
            fullWidth
            reset={true}
            margin="dense"
            type="password"
            className="my-1"
            variant="outlined"
            regex={validPassword}
            name="new_password_confirmation"
            label={t('forms.confirm_new_password')}
            autoComplete="new_password_confirmation"
            helperText="Password confirmation and Password must match"
            customCondition={(x, y) => x === y}
            toCompare={formValues?.new_password?.value || ''}
            onSetValue={(value) =>
              setFormValues({
                ...formValues,
                new_password_confirmation: value,
              })
            }
          />

          <FormControl className="px-2">
            <span className="text-xs md:text-sm">
              {t('forms.private.newsletter.text')}
            </span>

            <RadioGroup
              row
              className="my-2"
              name="policy-newsletter-group"
              aria-labelledby="policy-newsletter"
            >
              <FormControlLabel
                label={t('forms.yes')}
                control={
                  <Radio
                    size="small"
                    value={true}
                    checked={Boolean(formValues.newsletter.value)}
                  />
                }
                onChange={(v) =>
                  setFormValues({
                    ...formValues,
                    newsletter: {
                      value: true,
                      valid: true,
                    },
                  })
                }
              />
              <FormControlLabel
                label={t('forms.no')}
                control={
                  <Radio
                    size="small"
                    value={false}
                    checked={Boolean(!formValues.newsletter.value)}
                    onChange={(v) =>
                      setFormValues({
                        ...formValues,
                        newsletter: {
                          value: false,
                          valid: true,
                        },
                      })
                    }
                  />
                }
              />
            </RadioGroup>
          </FormControl>
        </form>
      )}

      {response?.isSuccess &&
        response?.data && [
          response?.data?.success && (
            <Alert severity="success">
              <AlertTitle>Success</AlertTitle>
              {t('forms.response.generic_success')}
            </Alert>
          ),
          !response?.data?.success && (
            <Alert
              severity="error"
              action={
                <Button
                  color="inherit"
                  size="small"
                  onClick={onCancel}
                >
                  {t('buttons.back')}
                </Button>
              }
            >
              <AlertTitle>Error</AlertTitle>
              {t('forms.response.generic_error')}
            </Alert>
          ),
        ]}

      {!response?.isSuccess && !response?.data && (
        <div className="w-full">
          <Button className="w-1/2" onClick={onCancel}>
            {t('buttons.cancel')}
          </Button>

          {response.isLoading ? (
            <LoadingButton
              loading
              className="h-10 w-1/2 bg-primary text-white"
            />
          ) : (
            <Button
              disabled={!allowSubmit}
              className={classNames(
                'h-10 w-1/2 bg-primary text-white',
                {
                  'pointer-events-none opacity-50': !allowSubmit,
                },
              )}
              onClick={onSubmit}
            >
              {t('buttons.save')}
            </Button>
          )}
        </div>
      )}
    </>
  );
}
